<template>
    <section id="contact-form">
        <v-countries-modal
            :li-scrolls="id_scroll_list_country"
            :show-modal="showCountriesWithPhoneCode"
            v-on:closemodal="toggleCountriesPhoneCode"
            type="delivery"
        ></v-countries-modal>
        <div class="row">
            <div class="col-lg-12">
                <h5 class="mb-5">Delivery Address</h5>
                <p>Please enter all the required field *</p>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
                        <label for="">Name <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" :value="getDeliveryAddress.name" @input="updateDeliverName" />
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
                        <label for="">Company Name</label>
                        <input type="text" class="form-control" :value="getDeliveryAddress.company_name" @input="updateDeliverCompanyName"/>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
                        <label for="">Address <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" :value="getDeliveryAddress.address" @input="updateDeliverAddress"/>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
                        <label for="">City <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" :value="getDeliveryAddress.city" @input="updateDeliverCity"/>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
                        <label for="">Postal Code <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" :value="getDeliveryAddress.postal_code" @input="updateDeliverPostalCode"/>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
                        <label for="">State <span class="text-danger">*</span></label>
                        <select
                            class="form-control"
                            @change="updateDeliverState"
                            v-if="getDeliveryAddress.country == 'US'"
                        >
                            <option value="">Select State</option>
                            <option
                                v-for="(item, index) in getStates"
                                :key="index"
                                :value="item.name"
                                :selected="getDeliveryAddress.state == item.name"
                            >
                            {{ item.name }}
                            </option>
                        </select>
                        <input
                            v-else
                            type="text"
                            class="form-control"
                            placeholder="State"
                            :value="getDeliveryAddress.state"
                            @input="updateDeliverState"
                        />
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
                        <label for="">Country <span class="text-danger">*</span></label>
                        <select type="text" class="form-control" :value="getDeliveryAddress.country" @input="updateDeliverCountry">
                            <option v-for="country in getCountries" 
                            :value="country.country_code">{{ country.name }}</option>
                        </select>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
                        <label for="">Phone No.<span class="text-danger">*</span></label>
                        
                        <div class="input-group mb-3">
                            <div class="input-group-prepend" @click="toggleCountriesPhoneCode(true)"
                                style="cursor: pointer; border-right: 1px solid">
                                <span class="input-group-text" id="basic-addon1" style="
                                    min-width: 80px;
                                    max-width: 80px;
                                    text-align: center;
                                    ">
                                    <img :src="getDeliveryAddress.phone_code.flag" alt="" class="mr-1" />
                                    <b style="width: 100%; text-align: center">{{
                                    getDeliveryAddress.phone_code.dial_code
                                    }}</b>
                                </span>
                            </div>
                            <input type="text" class="form-control" @input="updateDeliverContact"
                                v-mask="'(###)-###-####'" :value="getDeliveryAddress.contact"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from "vuex";
import { FETCH_COUNTRIES, FETCH_STATES } from "@/core/services/store/actions.type";
import vCountriesForPhoneCode from "@/components/frontend/modal/countriesforphonecode";
import {
    SET_DELIVERY_ADDRESS_NAME,
    SET_DELIVERY_ADDRESS_COMPANY_NAME,
    SET_DELIVERY_ADDRESS_ADDRESS,
    SET_DELIVERY_ADDRESS_CITY,
    SET_DELIVERY_ADDRESS_POSTAL_CODE,
    SET_DELIVERY_ADDRESS_STATE,
    SET_DELIVERY_ADDRESS_COUNTRY,
    SET_DELIVERY_ADDRESS_CONTACT,
} from "@/core/services/store/mutations.type";
export default {
    components: {
        "v-countries-modal": vCountriesForPhoneCode
    },
    created() {
        this.$store.dispatch(FETCH_COUNTRIES);      
        this.$store.dispatch(FETCH_STATES);  
    },
    computed: {
        ...mapGetters([
            "getCountries",
            "getDeliveryAddress",
            "getSelectedCountryPhoneCode",
            "getStates"
        ])
    },
    data() {
        return {
            id_scroll_list_country: "",
            showCountriesWithPhoneCode: false,
            errors: []
        };
    },
    methods: {
        toggleCountriesPhoneCode(flag) {
            if (flag) {
                this.id_scroll_list_country =
                "scroll_li" + this.getDeliveryAddress.phone_code.code;
            }
            this.showCountriesWithPhoneCode = flag;
        },
        updateDeliverName(e) {
            this.$store.commit(SET_DELIVERY_ADDRESS_NAME, e.target.value);
        },
        updateDeliverCompanyName(e) {
            this.$store.commit(SET_DELIVERY_ADDRESS_COMPANY_NAME, e.target.value);
        },
        updateDeliverAddress(e) {
            this.$store.commit(SET_DELIVERY_ADDRESS_ADDRESS, e.target.value);
        },
        updateDeliverCity(e) {
            this.$store.commit(SET_DELIVERY_ADDRESS_CITY, e.target.value);
        },
        updateDeliverPostalCode(e) {
            this.$store.commit(SET_DELIVERY_ADDRESS_POSTAL_CODE, e.target.value);
        },
        updateDeliverState(e) {
            this.$store.commit(SET_DELIVERY_ADDRESS_STATE, e.target.value);
        },
        updateDeliverCountry(e) {
            this.$store.commit(SET_DELIVERY_ADDRESS_COUNTRY, e.target.value);
        },
        updateDeliverContact(e) {
            this.$store.commit(SET_DELIVERY_ADDRESS_CONTACT, e.target.value);
        },
    }
}
</script>