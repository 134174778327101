<template>
    <div>
        <div class="row" v-if="show_page == 1">
            <div class="col-lg-12">
                <h5 class="mb-5">Apostille Service Rate</h5>
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-12 form-group mb-2 rounded m-2 p-3" style="background-color: white; user-select: none;" @click="updateExpeditedRate(false)">
                        <div class="d-flex h-100">
                            <div style="width: 5%; display: flex; align-items: center; justify-content: center;">
                                <input class="m-0 form-check-input" type="radio" :checked="!getIsExpeditedRate" />
                            </div>
                            <div style="width: 95%;" class="pl-3">
                                <h4>Regular - ${{ regular_rate }}</h4>
                                <p class="m-0">{{ default_rate_note }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 form-group mb-2 rounded m-2 p-3" style="background-color: white; user-select: none;" @click="updateExpeditedRate(true)">
                        <div class="d-flex h-100">
                            <div style="width: 5%; display: flex; align-items: center; justify-content: center;">
                                <input class="m-0 form-check-input" type="radio" :checked="getIsExpeditedRate" />
                            </div>
                            <div style="width: 95%;" class="pl-3">
                                <h4>Expedited - ${{ expedited_rate }}</h4>
                                <p class="m-0">{{ expedited_rate_note }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="show_page == 2">
            <div class="col-lg-12">
                <h5 class="mb-5">Apostille Additional Service Rate</h5>
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-12 form-group mb-2 rounded m-2 p-3" style="background-color: white; user-select: none;" @click="updateDocumentTranslation(!getTranslationRateStatus)">
                        <div class="d-flex h-100">
                            <div style="width: 5%; display: flex; align-items: center; justify-content: center;">
                                <input class="m-0 form-check-input" type="checkbox" :checked="getTranslationRateStatus" >
                            </div>
                            <div style="width: 95%;" class="pl-3">
                                <h4>Document Translation - ${{ doc_translation_rate }}</h4>
                                <p class="m-0">{{ doc_translation_rate_note }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 form-group mb-2 rounded m-2 p-3" style="background-color: white; user-select: none;" @click="updateDocumentScan(!getScanRateStatus)">
                        <div class="d-flex h-100">
                            <div style="width: 5%; display: flex; align-items: center; justify-content: center;">
                                <input class="m-0 form-check-input" type="checkbox" :checked="getScanRateStatus" >
                            </div>
                            <div style="width: 95%;" class="pl-3">
                                <h4>Document Scanning - ${{ doc_scan_rate }}</h4>
                                <p class="m-0">{{ doc_scan_rate_note }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {{  }}
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";

    import {
        SET_IS_SELECTED_EXPEDITED_RATE,
        SET_DOC_SCAN_RATE_STATUS,
        SET_DOC_TRANSLATION_RATE_STATUS,
        SET_TOTAL_SERVICE_AMOUNT
    } from "@/core/services/store/mutations.type";
    import {
        FETCH_F_CLIENT_RATE
    } from "@/core/services/store/actions.type";
    export default {
        props: {
            show_page: {
                type: Number,
                require: true,
            }
        },
        data(){
            return {
                client_data: {},
                regular_rate: 0,
                expedited_rate: 0,
                doc_translation_rate: 0,
                doc_scan_rate: 0,
                default_rate_note: "", 
                expedited_rate_note: "",
                doc_translation_rate_note: "",
                doc_scan_rate_note: ""
            }
        },
        watch: {
            client_data: function(){
                this.regular_rate = ((parseFloat(this.client_data.rate) * this.client_data.service.default_qty) + (parseFloat(this.client_data.rate) * this.client_data.service.default_qty * this.client_data.tax_percentage / 100)).toFixed(2);
                this.expedited_rate = ((this.client_data.expedited_rate * this.client_data.service.default_qty) + (this.client_data.expedited_rate * this.client_data.service.default_qty * this.client_data.tax_percentage / 100)).toFixed(2);
                this.doc_translation_rate = parseFloat(this.client_data.service.doc_translation_rate * this.getDocumentCount).toFixed(2);
                this.doc_scan_rate = parseFloat(this.client_data.service.doc_scan_rate).toFixed(2);

                this.default_rate_note = this.client_data.service.default_rate_note;
                this.expedited_rate_note = this.client_data.service.expedited_rate_note;
                this.doc_translation_rate_note = this.client_data.service.doc_translation_rate_note;
                this.doc_scan_rate_note = this.client_data.service.doc_scan_rate_note;
            }
        },
        computed: {
            ...mapGetters([
                "getScanRateStatus",
                "getTranslationRateStatus",
                "getIsExpeditedRate",
                "getServiceId",
                "getDocumentInformationList"
            ]),
            getDocumentCount(){
                const documents = this.getDocumentInformationList;

                let totalPageCount = documents.reduce((sum, document) => sum + document.pageCount, 0);

                return totalPageCount == 0 ? 1 : totalPageCount;
            }
        },
        created(){
            this.fetchRate();
        },
        methods: {
            updateDocumentTranslation(status){
                this.$store.commit(SET_DOC_TRANSLATION_RATE_STATUS, status);
                this.calculatePrice();
            },
            updateDocumentScan(status){
                this.$store.commit(SET_DOC_SCAN_RATE_STATUS, status);
                this.calculatePrice();
            },
            updateExpeditedRate(status){
                this.$store.commit(SET_IS_SELECTED_EXPEDITED_RATE, status);
                this.calculatePrice();
            },
            fetchRate(){
                // alert('test');
                var id = this.$route.params.id;
                var service_id = this.getServiceId;
                this.$store
                .dispatch(FETCH_F_CLIENT_RATE, {
                    id: id,
                    service_id: service_id
                })
                .then(data => {
                    this.client_data = data;
                    setTimeout(() => {
                        this.calculatePrice();
                    }, 1);
                })
                .catch(() => {});
            },
            calculatePrice(){
                var total_price = 0;

                if(this.getIsExpeditedRate){
                    total_price = parseFloat(this.expedited_rate);
                }else{
                    total_price = parseFloat(this.regular_rate);
                }

                if(this.getTranslationRateStatus){
                    total_price = total_price + parseFloat(this.doc_translation_rate);
                }
                if(this.getScanRateStatus){
                    total_price = total_price + parseFloat(this.doc_scan_rate);
                }

                this.$store.commit(SET_TOTAL_SERVICE_AMOUNT, parseFloat(total_price).toFixed(2));
            }
        }
    }
</script>