<template>
    <section id="contact-form">
        <v-countries-modal :li-scrolls="id_scroll_list_country" :show-modal="showCountriesWithPhoneCode"
            v-on:closemodal="toggleCountriesPhoneCode"></v-countries-modal>
        <div class="row">
            <div class="col-lg-12">
                <h5 class="mb-5">Contact Details</h5>
                <p>Please enter all the required field *</p>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
                        <label for="">First Name <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" :value="getApplicantFirstName"
                            @input="updateFirstName" />
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
                        <label for="">Middle Name</label>
                        <input type="text" class="form-control" :value="getApplicantMiddleName"
                            @input="updateMiddleName" />
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
                        <label for="">Last Name <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" :value="getApplicantlastName" @input="updateLastName" />
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
                        <label for="">Email <span class="text-danger">*</span></label>
                        <input type="email" class="form-control" :value="getApplicantEmail" @input="updateEmail" />
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
                        <label for="">Confirm Email <span class="text-danger">*</span></label>
                        <input type="email" class="form-control" :value="getApplicantConfirmEmail"
                            @input="updateConfirmEmail" 
                            @click.right.prevent
                            @paste.prevent/>
                        <span v-if="
                            getApplicantConfirmEmail != getApplicantEmail &&
                            getApplicantConfirmEmail != ''
                        " class="text-danger">Confirm email doesn't matched with email</span>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
                        <label for="">Phone No.<span class="text-danger">*</span></label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend" @click="toggleCountriesPhoneCode(true)"
                                style="cursor:pointer;border-right:1px solid">
                                <span class="input-group-text" id="basic-addon1"
                                    style="min-width: 80px;max-width: 80px; text-align: center;">
                                    <img :src="getSelectedCountryPhoneCode.flag" alt="" class="mr-1" />
                                    <b style="width: 100%;text-align: center;">{{
                                        getSelectedCountryPhoneCode.dial_code
                                    }}</b>
                                </span>
                            </div>
                            <input type="text" class="form-control" @input="updatePhoneNo" v-mask="'(###)-###-####'"
                                :value="getApplicantPhoneNo" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
import { mapGetters } from "vuex";
import vCountriesForPhoneCode from "@/components/frontend/modal/countriesforphonecode";
import {
    UPDATE_PHONENO,
    UPDATE_CONFIRMEMAIL,
    UPDATE_EMAIL,
    UPDATE_LASTNAME,
    UPDATE_MIDDLENAME,
    UPDATE_FIRSTNAME,
} from "@/core/services/store/mutations.type";
export default {
    components: {
        "v-countries-modal": vCountriesForPhoneCode
    },
    computed: {
        ...mapGetters([
            "getApplicantFirstName",
            "getApplicantMiddleName",
            "getApplicantlastName",
            "getApplicantEmail",
            "getApplicantConfirmEmail",
            "getApplicantPhoneNo",
            "getSelectedCountryPhoneCode",
            "getCountries"
        ])
    },
    data() {
        return {
            id_scroll_list_country: "",
            showCountriesWithPhoneCode: false,
            regEmail: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            errors: []
        };
    },
    methods: {
        toggleCountriesPhoneCode(flag) {
            if (flag) {
                this.id_scroll_list_country =
                    "scroll_li" + this.getSelectedCountryPhoneCode.code;
            }
            this.showCountriesWithPhoneCode = flag;
        },
        updateFirstName(e) {
            this.$store.commit(UPDATE_FIRSTNAME, e.target.value);
        },
        updateMiddleName(e) {
            this.$store.commit(UPDATE_MIDDLENAME, e.target.value);
        },
        updateLastName(e) {
            this.$store.commit(UPDATE_LASTNAME, e.target.value);
        },
        updateEmail(e) {
            this.validateEmail(e.target.value);
            this.$store.commit(UPDATE_EMAIL, e.target.value);
        },
        updateConfirmEmail(e) {
            this.$store.commit(UPDATE_CONFIRMEMAIL, e.target.value);
        },
        updatePhoneNo(e) {
            this.$store.commit(UPDATE_PHONENO, e.target.value);
        },
        validateEmail(value) {
            if (this.regEmail.test(value)) {
                this.errors["email"] = "";
            } else {
                this.errors["email"] = "Please enter a valid email address";
            }
        },
    }
}
</script>